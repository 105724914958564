
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Auth from 'domains/auth';
import SignInForm from './sign-in-form';
import { url as appUrl } from 'adapters/app';

class SignIn extends React.Component {
  static propTypes = {
    onSignIn: PropTypes.func.isRequired
  };

  handleSignIn = ({ email, password }) => {
    this.props.onSignIn({ email, password })
  };

  render() {
    return (
      <SignInForm
        onSignIn={this.handleSignIn}
        linkToForgotPassword={<Link to="/forgot-password">忘记密码？</Link>}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSignIn({ email, password }) {
      dispatch({
        type: 'api',
        request: () => Auth.signIn({ email, password }),
        success() { window.location.assign(appUrl); }
      });
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SignIn);
