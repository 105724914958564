
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Alert, Spin } from 'antd';

import SignIn from 'pages/sign-in';
import ForgotPassword from 'pages/forgot-password';
import ChangePassword from 'pages/change-password';
import { history } from 'domains/history';

import './index.scss';

class Container extends React.Component {
  static propTypes = {
    alert: PropTypes.object,
    spin: PropTypes.bool.isRequired,
    onCloseAlert: PropTypes.func.isRequired
  };

  renderAlert() {
    const {
      type,
      message
    } = this.props.alert;

    return (
      <Alert
        type={type}
        message={message}
        closable afterClose={this.props.onCloseAlert}
      />
    );
  }

  render() {
    return (
      <section id="app" className="container">
        <Spin spinning={this.props.spin} tip="加载中..." >
          <div className="alert">
            {this.props.alert && this.renderAlert()}
          </div>
          <div className="logo">
            <img src="logo-transparent-full.png" alt="logo" />
          </div>
          <div className="content">
            <Router history={history}>
              <Switch>
                <Route path="/forgot-password" exact><ForgotPassword /></Route>
                <Route path="/change-password" exact><ChangePassword /></Route>
                <Route path="/" exact><SignIn /></Route>
                <Redirect to="/" />
              </Switch>
            </Router>
          </div>
        </Spin>
      </section>
    );
  }
};

function mapStateToProps(state) {
  return {
    spin: state.spin,
    alert: state.alert
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCloseAlert() {
      dispatch({ type: 'closeAlert' });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
