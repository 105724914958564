
import { createStore, applyMiddleware, compose } from 'redux'

import { combinedReducer } from './reducers';
import { middlewares } from './middlewares';

const Store = {};

Store.init = (initialState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    combinedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

export default Store;
