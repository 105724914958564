
import React from 'react';
import {
  Button,
  Input,
  Form
} from 'antd';
import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';

import './sign-in-form.scss';

const SignInForm = ({
  onSignIn,
  linkToForgotPassword
}) => {  
  const emailField = (
    <Form.Item
      name="email"
      rules={[
        { required: true, message: `请输入电子邮件` },
        { type: 'email', message: '电子邮件格式错误' }
      ]}
    >
      <Input
        placeholder={"电子邮件"}
        prefix={<UserOutlined />}
      />
    </Form.Item>
  );

  const passwordField = (
    <Form.Item
      name="password"
      rules={[
        { required: true, message: `请输入密码` },
        { min: 8, message: '密码必须至少 8 个字符' }
      ]}
    >
      <Input.Password
        placeholder={"密码"}
        prefix={<LockOutlined />}
      />
    </Form.Item>
  );

  return (
    <div className="sign-in">
      <Form
        name="sign-in"
        onFinish={onSignIn}
      >
        {emailField}
        {passwordField}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            登录
          </Button>
        </Form.Item>
        {linkToForgotPassword}
      </Form>
    </div>
  );
};

export default SignInForm;
