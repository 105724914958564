
import axios from 'axios';
import env from 'env';

const {
  api: {
    rootURL
  }
} = env;

axios.defaults.withCredentials = true;

const API = {};

function constructMethod(action) {
  const actionToMethod = {
    create: 'POST',
    readList: 'GET',
    read: 'GET',
    update: 'POST',
    delete: 'POST'
  };

  const method = actionToMethod[action];

  return method === undefined ?
    'POST' :
    method;
}

function constructPath(action, id) {
  const actionToPath = {
    create: false,
    readList: false,
    read: id,
    update: id,
    delete: `${id}/delete`
  };

  const path = actionToPath[action];

  return path === undefined ?
    action :
    path;
}

API.request = async ({ resource, action, data }) => {
  const method = constructMethod(action);
  const path = constructPath(action, data && data.id);
  const url = `${rootURL}/${resource}${path ? `/${path}` : ''}`;

  try {
    const result = await axios({
      method,
      url,
      data
    });

    return {
      status: result.status,
      data: result.data
    };
  } catch(e) {
    if (!e.response) return {
      error: {
        message: '无法连接到服务器，请稍后再试'
      }
    }
    
    return {
      status: e.response.status,
      data: e.response.data
    };
  }
};

export default API;
