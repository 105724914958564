
import React from 'react';
import {
  Form,
  Input,
  Button,
  Row, Col
} from 'antd';
import {
  UserOutlined
} from '@ant-design/icons';

import './forgot-password-form.scss';

function ForgotPasswordForm({
  onRequestCode,
  onSubmitCode,
  linkToSignIn
}) {
  const emailField = (
    <Form.Item
      name="email"
      rules={[
        { required: true, message: `请输入电子邮件地址` },
        { type: 'email', message: '电子邮件地址格式错误' }
      ]}
    >
      <Input
        placeholder={"电子邮件"}
        prefix={<UserOutlined />}
      />
    </Form.Item>
  );

  const requestCodeButton = (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
      >
        获取验证码
      </Button>
    </Form.Item>
  );

  const requestCodeForm = (
    <Form
      name="requestCode"
      onFinish={onRequestCode}
    >
      <p>请输入电子邮件地址</p>
      <br />
      <Row gutter={8}>
        <Col span={16}>
          {emailField}
        </Col>
        <Col span={8}>
          {requestCodeButton}
        </Col>
      </Row>
    </Form>
  );

  const codeField = (
    <Form.Item
      name="code"
      rules={[
        { required: true, message: `请输入验证码` },
        { len: 6, message: '验证码为 6 位字符' }
      ]}
    >
      <Input
        placeholder={"验证码"}
      />
    </Form.Item>
  );

  const submitCodeButton = (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
      >
        提交验证码
      </Button>
    </Form.Item>
  );


  const submitCodeForm = (
    <Form
      name="submitCode"
      onFinish={onSubmitCode}
    >
      {codeField}
      {submitCodeButton}
    </Form>
  );

  return (
    <div className="forgot-password">
      {requestCodeForm}
      {submitCodeForm}
      {linkToSignIn}
    </div>
  );
}

export default ForgotPasswordForm;
