
import development from './development';
import production from './production';

const envName = process.env.NODE_ENV || 'development';
const envVars = envName === 'production' ? production : development;

export default {
  name: envName,
  ...envVars
};
