
import React from 'react';
import {
  Form,
  Input,
  Button
} from 'antd';
import {
  LockOutlined
} from '@ant-design/icons';

import './change-password-form.scss';

function ChangePasswordForm({
  onChangePassword
}) {
  const passwordField = (
    <Form.Item
      name="password"
      rules={[
        { required: true, message: `请输入密码` },
        { min: 8, message: '密码必须至少 8 个字符' }
      ]}
    >
      <Input.Password
        placeholder={"密码"}
        prefix={<LockOutlined />}
      />
    </Form.Item>
  );

  const confirmPasswordField = (
    <Form.Item
      name="confirmPassword"
      rules={[
        { required: true, message: '确认密码' },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject('密码匹配失败');
          }
        })
      ]}
      dependencies={['password']}
    >
      <Input.Password
        placeholder={"确认密码"}
        prefix={<LockOutlined />}
      />
    </Form.Item>
  );

  return (
    <div className="change-password">
      <Form
        name="ChangePassword"
        onFinish={onChangePassword}
      >
        {passwordField}
        {confirmPasswordField}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            更改密码
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangePasswordForm;
