
// TODO:
import API from 'adapters/api';
// import { url } from 'adapters/apps';

const Init = {};

Init.bootstrap = async () => {
  const {
    status,
    data
  } = await API.request({
    resource: 'bootstrap/cdp-auth',
    action: 'readList'
  });

  if (status >= 200 && status < 300) {
    return { redirect: true };
  } else {
    return { initialState: data };
  }
};

export default Init;
