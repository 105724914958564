
import API from 'adapters/api';

const Auth = {};

Auth.signIn = async ({ email, password }) => {
  return await API.request({
    resource: 'user-sessions',
    action: 'create',
    data: { email, password }
  });
};

Auth.requestCode = async ({ email }) => {
  return await API.request({
    resource: 'users',
    action: 'request-password-reset-code',
    data: { email }
  });
};

Auth.submitCode = async ({ email, code }) => {
  return await API.request({
    resource: 'users',
    action: 'submit-password-reset-code',
    data: { email, code }
  });
};

Auth.changePassword = async ({ email, code, password }) => {
  return await API.request({
    resource: 'users',
    action: 'change-password-via-code',
    data: { password, email, code }
  })
}

const reducers = {
  passwordResetInfo(state, action) {
    if (state === undefined) return {};

    if (action.type === 'setPasswordResetCode') {
      return Object.assign({}, state, { code: action.code });
    } else if (action.type === 'setPasswordResetEmail') {
      return Object.assign({}, state, { email: action.email });
    } else if (action.type === 'clearPasswordResetInfo') {
      return {};
    }

    return state;
  }
};

export { reducers };
export default Auth;
