
import env from 'env';

const {
  app: {
    url
  }
} = env;

export { url };
