
const UI = {};

const reducers = {
  spin(state, action) {
    if (state === undefined) return false;
  
    if (action.type === 'startSpin') {
      return true;
    } else if (action.type === 'stopSpin') {
      return false;
    }
  
    return state;
  },
  alert(state, action) {
    if (state === undefined) return null;
  
    if (action.type === 'alert') {
      return {
        type: action.alert.type,
        message: action.alert.message
      };
    } else if (action.type === 'closeAlert') {
      return null;
    }
  
    return state;
  }
};

export { reducers };
export default UI;
