

import { combineReducers } from 'redux';

import { reducers as uiReducers } from 'domains/ui';
import { reducers as authReducers } from 'domains/auth';

const combinedReducer = combineReducers(Object.assign(
  {},
  authReducers,
  uiReducers
));

export { combinedReducer };
