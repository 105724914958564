
const api = store => next => async (action) => {
  if (action.type !== 'api') return next(action);

  store.dispatch({ type: 'closeAlert' });
  store.dispatch({ type: 'startSpin' });
  const result = await action.request();
  const { status, data } = result;

  store.dispatch({ type: 'stopSpin' });

  action.before && action.before();
  
  let success = false;
  let message;

  if (!status) {
    message = '无法连接到服务器，请稍后再试'
  } else if (status >= 400) {
    message = data.message || '未知错误';
  } else {
    success = true;
    message = data.message;
  }

  store.dispatch({
    type: 'alert',
    alert: {
      type: success ? 'success' : 'error',
      message
    }
  });

  success && action.success && action.success(data);
  action.after && action.after();
}

const middlewares = [api];

export { middlewares };
