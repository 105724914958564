
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Auth from 'domains/auth';
import { history } from 'domains/history';
import ChangePasswordForm from './change-password-form';

class ChangePassword extends React.Component {
  static propTypes = {
    passwordResetInfo: PropTypes.object.isRequired,
    onChangePassword: PropTypes.func.isRequired
  };

  state = {
    redirect: false
  };

  handleChangePassword = async ({ password }) => {
    const { email, code } = this.props.passwordResetInfo;
    this.props.onChangePassword({
      code,
      email,
      password
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <ChangePasswordForm
        onChangePassword={this.handleChangePassword}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    passwordResetInfo: state.passwordResetInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangePassword({ code, email, password }) {
      dispatch({
        type: 'api',
        request: () => Auth.changePassword({ code, email, password }),
        success: () => {
          dispatch({ type: 'clearPasswordResetInfo' });
          history.push('/');
        }
      });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
