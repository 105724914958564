
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Auth from 'domains/auth';
import { history } from 'domains/history';
import ForgotPasswordForm from './forgot-password-form';

class ForgotPassword extends React.Component {
  static propTypes = {
    onRequestCode: PropTypes.func.isRequired,
    onSubmitCode: PropTypes.func.isRequired
  };

  state = {
    redirect: false
  };

  handleRequestCode = async ({ email }) => {
    this.props.onRequestCode({ email });
  };

  handleSubmitCode = async ({ code }) => {
    const { email, onSubmitCode } = this.props;
    onSubmitCode({ email, code });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/change-password" />;
    }

    return (
      <ForgotPasswordForm
        linkToSignIn={<Link to="/">返回登录页</Link>}
        onRequestCode={this.handleRequestCode}
        onSubmitCode={this.handleSubmitCode}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.passwordResetInfo.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRequestCode({ email }) {
      dispatch({
        type: 'api',
        request: () => Auth.requestCode({ email }),
        success: () => {
          dispatch({
            type: 'setPasswordResetEmail',
            email
          });
        }
      });
    },
    onSubmitCode({ email, code }) {
      dispatch({
        type: 'api',
        request: () => Auth.submitCode({ email, code }),
        success: () => {
          dispatch({
            type: 'setPasswordResetCode',
            code
          });
          history.push('/change-password');
        }
      });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
