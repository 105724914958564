
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Store from 'state/store';
import Init from 'domains/init';
import Container from 'pages/container';
import { url as appUrl } from 'adapters/app';
import './hacks';

import 'antd/dist/antd.css';

import './reset.css';
import './index.scss';

Init.bootstrap().then(({ redirect, initialState }) => {
  if (redirect) {
    window.location.assign(appUrl);
    return;
  }

  const store = Store.init(initialState);

  render();
  store.subscribe(render);

  function render() {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <Container />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }  
});
